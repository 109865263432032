<template>
	<main>
		<HeaderTab :title="$t('model.list_model')" />

		<div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
						<Model
							v-if="licence_id"
							:modelable_id="licence_id"
							:modelable_types="['licence']"
							edit_route="configModelClientArea"
						/>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">
    export default {
        name: "ModelClientArea",
        data () {
            return {
				licence_id: null,
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
				this.licence_id = await this.getAsyncConfig('licence')
            }
        },
        components: {
			HeaderTab: () => import('@/components/HeaderTab'),
            Model : () => import('@/components/Model/Model')
        }
    }
</script>
